export const binaryToFileConverter = (binaryData, title) => {
  const base64WithoutPrefix = binaryData.replace(/^data:.*;base64,/, ""); // Clean the base64 string

  // Decode the base64 string to binary
  const byteCharacters = atob(base64WithoutPrefix);
  const byteNumbers = Array.from(byteCharacters, char => char.charCodeAt(0));
  const byteArray = new Uint8Array(byteNumbers);


  // Create a Blob from the byte array
  const blob = new Blob([byteArray],);

  // Create a download link
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = title;

  // Append the link to the body
  document.body.appendChild(link);

  // Trigger the click event on the link
  link.click();

  // Remove the link from the body
  document.body.removeChild(link);
};
